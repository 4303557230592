.wwa-middle-container div {
    width: 20%;

    padding: 2%;

    border-radius: 25px;
    background-color: rgb(110, 143, 183, 0.5);

    text-align: center !important;
}

.wwa-middle-container-mobile div {

    padding: 2%;

    border-radius: 25px;
    background-color: rgb(110, 143, 183, 0.5);

    text-align: center !important;
}

.wwa-middle-container img {
    width: 100%;

    margin-bottom: 0.5rem;

    border-radius: 5%;
}

.wwa-middle-container-mobile img {
    width: 100%;

    margin-bottom: 0.5rem;

    border-radius: 5%;
}

.wwo-label {
    color: white;

    margin-bottom: 0.5rem;

    font-weight: 600;
}

.p-description {
    color: white;

    margin-bottom: 0;

    font-size: 12px !important;
}
