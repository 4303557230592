html, body {
    height: 100%;

    margin: 0;

    font-family: "Montserrat", monospace;
}

.background-image-container {
    width: 100%;
    height: 100%;

    margin: 0;

    background-image: url("./assets/images/background.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    /* background-size: 100% 100%; */
}

.h-title, h1 {
    margin-top: 5%;
    margin-bottom: 2%;

    padding-left: 10%;
    padding-right: 10%;

    text-align: center;

    font-size: 26px;
    font-weight: 600;
}

.h-title-mobile, h1 {
    margin-top: 10%;
    margin-bottom: 10%;

    padding-left: 10%;
    padding-right: 10%;

    text-align: center;

    font-size: 26px;
    font-weight: 600;
}

.p-title {
    margin-top: 2%;
    margin-bottom: 5%;

    padding-left: 10%;
    padding-right: 10%;

    text-align: center;

    font-size: 20px;
    font-weight: 300;

    color: white;
}

.p-title-mobile {
    margin-top: 5%;
    margin-bottom: 10%;

    padding-left: 10%;
    padding-right: 10%;

    text-align: center;

    font-size: 20px;
    font-weight: 300;

    color: white;
}

.p-title-black {
    color: black !important;
}

.p-title-justify {
    text-align: justify !important;
}

.p-monospace {
    margin-top: 5%;

    font-weight: 600;
    letter-spacing: 5px;
}

.scrollable {
    height: 100%;

    padding-top: 4%;

    overflow: auto;

    font-family: "Montserrat", monospace;
}

.middle-container {
    display: flex;
    justify-content: space-between;

    margin-left: 10%;
    margin-right: 10%;
}

.middle-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin-left: 10%;
    margin-right: 10%;

    padding-bottom: 20%;
}

.end-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-self: center;
    gap: 0.5rem;

    margin-left: auto;
}

.image-button {
    border: none !important;
    background: none !important;

    --bs-btn-color: none !important;
    --bs-btn-bg: none !important;
    --bs-btn-border-color: none !important;
    --bs-btn-hover-color: none !important;
    --bs-btn-hover-bg: none !important;
    --bs-btn-hover-border-color: none !important;
    --bs-btn-focus-shadow-rgb: none !important;
    --bs-btn-active-color: none !important;
    --bs-btn-active-bg: none !important;
    --bs-btn-active-border-color: none !important;
    --bs-btn-active-shadow: none !important;
    --bs-btn-disabled-color: none !important;
    --bs-btn-disabled-bg: none !important;
    --bs-btn-disabled-border-color: none !important;
}

