.ff-p-title {
    margin-bottom: 0 !important;

    text-align: left !important;
}

.images-container {
    display: flex;
    justify-content: space-between;

    margin-top: 2%;
    margin-left: 20%;
    margin-right: 20%;
}

.images-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;

    padding-bottom: 20%;
}

.images-container-mobile > * {
    margin: auto;
}
