.menu-btn {
    position: absolute;

    margin-top: 1rem;
    left: 0.5rem;
}

.root-off-canvas {
    --bs-offcanvas-bg: rgb(111, 144, 183) !important;
    --bs-offcanvas-width: 80% !important;
    --bs-offcanvas-height: 100% !important;
    --bs-offcanvas-padding-x: 0 !important;
    --bs-offcanvas-padding-y: 0 !important;
}

.header-links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    padding: 1rem;
}

.header-links-container hr {
    width: 100%;

    color: white;
}

.footer-links-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    width: 80%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 1rem;
}

.footer-links-container hr {
    width: 100%;

    color: white;
}


.footer-links-container svg {
    width: 20px;
    height: 20px;

    margin-left: 0 !important;
    margin-right: 0.5rem;
}

/*

.header-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;

    padding-left: 0.5rem;
    padding-right: 0.5rem;

    display: flex;

    background: rgb(111, 144, 183);

    font-size: 14px;
    font-family: "Montserrat", monospace;
}

.header-image {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.header-item {
    display: flex;

    color: white;

    text-decoration: none;

    margin-top: auto;
    margin-bottom: auto;
}

.header-item:hover {
    color: black;
    cursor: pointer;
}

.header-item:active {
    color: black;
}

.header-item-active {
    color: black;
    text-decoration: none;
}

.footer-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 10px 25%;

    display: flex;
    justify-content: space-between;

    background-color: rgba(111, 144, 183) !important;

    text-align: center;

    font-size: 16px;
    font-family: "Montserrat", monospace;
}

.footer-link {
    display: flex;

    color: white;

    text-decoration: none;
}

.footer-link svg {
    width: 20px;
    height: 20px;

    margin-left: 2rem;
    margin-right: 0.5rem;
}
*/
