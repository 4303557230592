.body {
}

.home-background-image-container {
    width: 100%;
    height: 100%;

    margin: 0;

    background-image: url("../../assets/images/home-image-min.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    /* background-size: 100% 100%; */

    font-family: "Montserrat", monospace;
}

.background-image-div-container {
    background-color: rgb(110, 143, 183, 0.7);
}

.home-middle-container div {
    width: 30%;

    padding: 2%;

    margin: 0 auto;

    text-align: center;

    border-radius: 25px;
    background-color: white;
}

.home-middle-container-mobile div {
    padding: 2%;

    margin: 0 auto;

    text-align: center;

    border-radius: 25px;
    background-color: white;
}

.mobile-separator {
    margin: 3rem 10% 2rem;
    border-top: 0.2rem solid;
    color: white;
    opacity: 0.5;
}

.home-middle-container .title {
    margin-bottom: 10%;

    font-size: 16px;
    font-weight: 600;
}

.home-middle-container p {
    text-align: justify;

    font-size: 16px;
}

.middle-container-one {
    margin: auto !important;

    background-color: transparent !important;
}

.middle-container-one p {
    font-size: 30px;
    font-weight: 600;

    text-align: center;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);

    color: white;

    text-transform: uppercase;
}

.middle-container-two {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.middle-container-three {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
}

.middle-container-three-mobile {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
