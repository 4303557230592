.ep-p-title {
    text-align: left !important;

    margin-bottom: 0 !important;
}

.ep-label {
    font-style: italic;
    font-weight: 600;

    color: black;
}

.h2-title {
    margin-top: 3%;
    margin-bottom: 3%;

    padding-left: 10%;
    padding-right: 10%;

    text-align: center;
    font-weight: 600;

    font-size: 24px;
    font-style: italic;
    color: rgb(110, 143, 183);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.h2-title-mobile {
    margin-top: 10%;
    margin-bottom: 10%;

    padding-left: 10%;
    padding-right: 10%;

    text-align: center;
    font-weight: 600;

    font-size: 24px;
    font-style: italic;
    color: rgb(110, 143, 183);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.ep-middle-container {
    display: flex !important;
    align-items: flex-start !important;

    margin-left: 20% !important;
    margin-right: 20% !important;
}

.ep-middle-container-mobile {
    display: flex !important;
    align-items: flex-start !important;
}

.projects-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 1rem;

    margin: 0 !important;
}

.button-link {
    width: 100%;
}

.ep-button {
    width: 80% !important;

    padding-top: 10px !important;
    padding-bottom: 10px !important;

    border-style: dotted !important;

    --bs-btn-color: #000 !important;
    --bs-btn-bg: #fff !important;
    --bs-btn-border-color: #F0C98A !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #EDBE70 !important;
    --bs-btn-hover-border-color: #F6B74E !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #F6B74E !important;
    --bs-btn-active-border-color: #F6B74E !important;
    --bs-btn-disabled-color: #6c757d !important;
    --bs-btn-disabled-bg: transparent !important;
    --bs-btn-disabled-border-color: #F0C98A !important;
    --bs-gradient: none !important;

    border-radius: 15px !important;

    text-transform: uppercase !important;
}

.ep-button-mobile {
    width: 100% !important;
}

.consult-button-mobile {
    margin-top: 10%;
}
