.container > *:first-child {
    margin-bottom: 2rem;
}

.clinic-container {
    display: flex;
    flex-direction: row;

    margin: auto;

    width: 80%;
}

.clinic-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin: auto;

    width: 80%;
}

.clinic-container-p {
    margin-left: 2rem;
    margin-bottom: 0;

    color: black;

    align-self: center;
    text-align: justify;
}

.clinic-container-p-mobile {
    margin-bottom: 0;

    color: black;

    align-self: center;
    text-align: justify;
}

.clinic-container-label {
    font-style: italic;
    font-weight: 600;
}

.clinic-container-image {
    width: 10rem;
    height: 5rem;

    align-self: center;
    align-items: start;
}

.clinic-container-image-mobile {
    width: 100%;
    height: 50%;

    margin: auto;

    align-self: center;
    align-items: start;
}

.mobile-separator {
    color: rgb(111, 144, 183);
}
